export const appConfig = {
    // PREFIX FOR CLIENT
    client_prefix: 'CCI-',
    // MEMBER REGISTRATION
    use_ssn_flag: false,
    use_dob_flag: true,
    // PROVIDER DIRECTORY
    use_provider_directory_api: true,
    // FHIR RESOURCES
    use_observation_resource: true,
    use_eob_member_responsibility: false,
    // MEMBER SEARCH FIELDS
    use_member_search_policy_number: false,
    // EOB CUSTOMIZATIONS
    use_eob_total: true,
    // CLINICAL DATA    
    show_allergy_intolerance: false,
    show_condition: false,
    show_medication: false,
    show_procedure: false,
    show_immunization: false,
    // AVAILABLE APPS
    available_apps_flexpa: true,
}